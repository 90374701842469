import React from 'react';
import Profile from '../components/Profile';

const ProfilePage = () => {
  return (
    <div className="profile-page">
      <Profile />
    </div>
  );
}

export default ProfilePage;
