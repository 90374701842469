import React, { useEffect, useState, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/Navbar.css';
import Avatar from '@mui/material/Avatar';
import { throttle } from 'lodash';

const Navbar = () => {
  const dropdownRef = useRef(null);
 
  const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('PoribortonchaiUserData')));
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  const getUserFromUrl = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const userParam = urlParams.get('user');
    
    if (userParam) {
      try {
        const userObject = JSON.parse(decodeURIComponent(userParam));
        return userObject;
      } catch (error) {
        console.error('Error parsing user data after Google redirection:', error);
      }
    }
    return null;
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const userData = getUserFromUrl();
    const ideaParam = new URLSearchParams(window.location.search).get('idea') || "";
    const additionalInfoParam = new URLSearchParams(window.location.search).get('additionalInfo') || "";

    if (userData && !localStorage.getItem('PoribortonchaiUserData')) {
      localStorage.setItem('PoribortonchaiUserData', JSON.stringify(userData));
      setUserInfo(userData); 

      if (additionalInfoParam === 'not_found') {
        navigate("/profile");
      } else if (ideaParam !== 'undefined' && ideaParam) {
        navigate(`/${ideaParam}`);
      } else {
        navigate("/pitch");
      }
    }


    // Function to handle clicks outside of the dropdown
  function handleClickOutside(event) {
    if (dropdownOpen && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false);
    }
  }

  // Bind the event listener
  document.addEventListener('mousedown', handleClickOutside);

  return () => {
    // Unbind the event listener on clean up
    document.removeEventListener('mousedown', handleClickOutside);
  };
  }, [dropdownOpen]); // Only run this effect once on mount

  const handleLogout = () => {
    localStorage.removeItem('PoribortonchaiUserData');
    setUserInfo(null);
    navigate('/'); // Use navigate for internal routing
  };
  
  const throttledAvatar = useCallback(throttle(() => {
    return (
      <Avatar
        className="ellipse-2750"
        loading="lazy"
        src={userInfo?.profilePicture || '/UserDetails/userNotFound.png'}
        onClick={toggleDropdown}
      />
    );
  }, 5000), [userInfo, toggleDropdown]);

  return (
    <header className="header">
      <div className="logo-container">
        <Link to="/" style={{ textDecoration: "none" }}>
          <img src="/logo.png" alt="Logo" className="logo" />
        </Link>
      </div>
      <div className="nav">
        {userInfo ? (
          <div className="profile-container">
            <div>
              {throttledAvatar()}
              <div className={`dropdown ${dropdownOpen ? 'show' : ''}`} ref={dropdownRef}>
                <ul className="dropdown-menu" onClick={() => setDropdownOpen(false)}>
                <li>
                    <Link to="/my-ideas">
                      <div className="nav_frame-1171277397">
                        <div className="my-ideas">
                          <div className='nav-dropdown-svg'>
                            <div>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6354 10.0452C12.6354 11.5002 11.4554 12.6793 10.0004 12.6793C8.54538 12.6793 7.36621 11.5002 7.36621 10.0452C7.36621 8.58932 8.54538 7.41016 10.0004 7.41016C11.4554 7.41016 12.6354 8.58932 12.6354 10.0452Z" stroke="#2E3A59" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                              <path fill-rule="evenodd" clip-rule="evenodd" d="M9.99866 16.1309C13.172 16.1309 16.0745 13.8493 17.7087 10.0459C16.0745 6.2426 13.172 3.96094 9.99866 3.96094H10.002C6.82866 3.96094 3.92616 6.2426 2.29199 10.0459C3.92616 13.8493 6.82866 16.1309 10.002 16.1309H9.99866Z" stroke="#2E3A59" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            </div>
                            <div style={{marginTop: "3px"}}>
                            View Profile
                            </div>
                          </div>
                       
                        </div>
                      </div>
                    </Link>
                  </li>
                  
                  <li><hr className="Nav-dropdown-li-divider" /></li>
                  <li>
                    <Link to="/profile">
                      <div className='nav-dropdown-svg'>
                        <div>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M11.417 16.5846H16.7312" stroke="#2E3A59" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.7126 4.13097C11.3268 3.34847 12.3193 3.38931 13.1026 4.00347L14.2609 4.91181C15.0443 5.52597 15.3218 6.47847 14.7076 7.26264L7.80009 16.0751C7.56926 16.3701 7.21676 16.5443 6.84176 16.5485L4.17759 16.5826L3.57426 13.9868C3.48926 13.6226 3.57426 13.2393 3.80509 12.9435L10.7126 4.13097Z" stroke="#2E3A59" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                          <path d="M9.41895 5.78125L13.4139 8.91292" stroke="#2E3A59" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                        </div>
                        <div>
                          <div className="nav_frame-1171277397" style={{marginTop: "-12px"}}>
                            <div className="my-ideas">Edit Profile</div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </li>
                  <li><hr className="Nav-dropdown-li-divider" /></li>
                  <li onClick={handleLogout}>
                    <div className='nav-dropdown-svg'>
                      <div>
                      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.5129 6.15693V5.37943C12.5129 3.68359 11.1379 2.30859 9.44207 2.30859H5.37957C3.68457 2.30859 2.30957 3.68359 2.30957 5.37943V14.6544C2.30957 16.3503 3.68457 17.7253 5.37957 17.7253H9.4504C11.1412 17.7253 12.5129 16.3544 12.5129 14.6636V13.8778" stroke="#2E3A59" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M18.1738 10.0182H8.13965" stroke="#2E3A59" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M15.7344 7.58594L18.1744 10.0151L15.7344 12.4451" stroke="#2E3A59" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>

                      </div>
                      <div>
                        <div className="nav_frame-1171277398" style={{marginTop: "-12px"}}>
                          <div className="log-out">Log out</div>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div>
              <Link to="/pitch" className="pitch-button signed_in_pitch-button">Pitch my idea</Link>
            </div>
          </div>
        ) : (
          <div>
            <Link to={`${process.env.REACT_APP_BACKEND}/auth/google`} className="pitch-button signed_in_pitch-button">
              Pitch my idea
            </Link>
          </div>
        )}
      </div>
    </header>
  );
}

export default Navbar;
