import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet
import IdeaDetails from '../components/IdeaDetails';

const IdeaDetailsPage = () => {
  const { id } = useParams();
  const [idea, setIdea] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BACKEND}/all/ideas/${id}`)
      .then(response => {
        setIdea(response.data);
        setLoading(false);
      })
      .catch(error => {
        setError('Error fetching idea details');
        setLoading(false);
      });


  }, [id]);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const pageTitle = `Please vote for this idea to bring Poriborton to our beloved Bangladesh 🇧🇩 | ${idea?.title || 'Idea Details'}`;
  const pageDescription = idea?.description?.slice(0, 150) || 'Explore this idea and help bring positive changes to Bangladesh by voting.';

  return (
    <div>
      {idea ? <IdeaDetails idea={idea} /> : <p>No idea found</p>}
    </div>
  );
}

export default IdeaDetailsPage;
