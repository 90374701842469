import Axios from 'axios'
import { ALL_CETEGORY_FAIL, ALL_CETEGORY_REQUEST, ALL_CETEGORY_SUCCESS } from "../constants/CategoryConstants";

export const AllCategoryDataFetch = () => async (dispatch) => {
    dispatch({ type: ALL_CETEGORY_REQUEST});
    try {
      const { data } = await Axios.get(`${process.env.REACT_APP_BACKEND}/all/categories`);
      dispatch({ type: ALL_CETEGORY_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: ALL_CETEGORY_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
};