import { FEEDBACK_POST_FAIL, FEEDBACK_POST_REQUEST, FEEDBACK_POST_SUCCESS } from "../constants/FeedbackConstants";

export const FeedbackReducer = (state = {}, action) => {
    switch (action.type) {
      case FEEDBACK_POST_REQUEST:
        return { loading: true };
      case FEEDBACK_POST_SUCCESS:
        return { loading: false, feedBackInfo: action.payload };
      case FEEDBACK_POST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };