export const PeivacyPolicyData = [
    {
        title: "1. Introduction",
        details: "\"Poriborton Chai\" values your privacy. This Privacy Policy explains how we collect, use, and protect your personal information when you use our app."
    },
    {
        title: "2. Information We Collect",
        details: "When you sign in to our app, we collect:",
        listData: ["Google account details (email, name, etc.)", 
                    "Date of birth", "Occupation", "Current city"],
        moreInfo: "We also collect any content you submit, including ideas, images, votes, reports, and any ideas you share via social media."
    },
    {
        title: "3. How We Use Your Information",
        details: "The information we collect is used to: ",
        listData: ["Authenticate your account",
            "Allow you to submit, vote on, share, and download ideas",
            "Ensure compliance with app policies (e.g., voting limits)",
            "Track the total number of submitted ideas and vote counts",
            "Improve the user experience and app functionality"
        ]
    },
    {
        title: "4. Data Sharing and Disclosure",
        details: "We will not sell, rent, or lease your personal information to third parties. However, when you share an idea via external platforms like Facebook, WhatsApp, etc., the shared information will be subject to the privacy policies of those platforms."
    },
    {
        title: "5. Data Security",
        details: "We take reasonable measures to protect your personal data from unauthorized access or disclosure. However, no data transmission over the internet is completely secure, and we cannot guarantee the absolute security of your data."
    },
    {
        title: "6. Third-Party Services",
        details: "We use Google authentication for account sign-in. By using this service, you agree to Google’s privacy policy. We do not control how Google handles your data."
    },
    {
        title: "7. Cookies",
        details: "The app may use cookies to enhance your experience, such as remembering your preferences. You can disable cookies in your browser settings, but some features may not function properly."
    },
    {
        title: "8. Data Retention",
        details: "We retain your data for as long as your account is active or as needed to provide our services. If you delete your account, we will remove your personal data, but non-personal information (such as anonymized data) may be retained."
    },
    {
        title: "9. Your Rights",
        details: "You have the right to:",
        listData: ["Access your personal data",
            "Request correction of incorrect data",
            "Request deletion of your account"
        ],
        moreInfo: "To exercise any of these rights, please contact us through the app’s support feature."
    },
    {
        title: "10. Changes to This Privacy Policy",
        details: "We may update this policy from time to time. Users will be notified of significant changes. Your continued use of the app signifies your acceptance of the updated policy."
    },
    {
        title: "11. Contact Information",
        details: "If you have any questions about this Privacy Policy, please contact us at support@poribortonchai.com."
    },
   
];
