import React, { useEffect, useState } from 'react';
import { Box, Modal, Button, Typography } from '@mui/material';
import "./css/IdeaSubmissionModal.css";
import { useNavigate } from 'react-router-dom';
import SocialIdeaDetails from '../IdeaDetails/SocialsIdeaDetails';
import SocialsIdeaIconsForModal from '../IdeaDetails/SocialsIdeaIconsForModal';

const IdeaSubmissionModal = (props) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [ideaID, setIdeaId] = useState(true);
  const handleOpen = () => setOpen(true);

  const handleBackToHome = () => {
    navigate('/');
  };

  const handleViewMyIdea = () => {
    navigate("/idea/"+props.idea_id);
  };

  // Modal Styles
//   const style = {
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     border: "none",
//     transform: 'translate(-50%, -50%)',
//     width: 390,
//     bgcolor: 'background.paper',
//     boxShadow: 24,
//     pl: 5,
//     pr: 5
//   };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box className="idea-modal-container" style={{padding: "20px"}}>
        <img src="/thank-you.png" alt="Thank You" className="idea-modal-image" />

            <p className="idea-modal-title">
                Thank You!
            </p>

            <div className="your-idea-has-been-pitched-main">
                <div className="your-idea-has-been-pitched">Your idea has been pitched!</div>
            </div>

            <Typography className="idea-modal-message" style={{marginBottom: "20px"}}>
            Now let’s turn your awesome idea into a winner this month! Share your idea with friends and get more votes to increase your chances of being one of the top 3 winners. Share now and watch your idea rise to the top!
            </Typography>
            <SocialsIdeaIconsForModal ideaId={props.idea_id}/>
            <div className="idea-modal-button-group">
            <button
                variant="contained"
                className="idea-modal-back-btn"
              
                onClick={handleBackToHome}
            >
                Back to Home
            </button>
            <button
                variant="contained"
                className="idea-modal-view-btn"
                onClick={handleViewMyIdea}
            >
                View My Idea
            </button>
            </div>
        </Box>
      </Modal>
    </div>
  );
};

export default IdeaSubmissionModal;
