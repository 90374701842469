export const termsAndConditionsData = [
    {
        title: "1. Acceptance of Terms",
        details: "By using the \"Poriborton Chai\" app, you agree to comply with and be bound by these Terms & Conditions. Please review these terms carefully. If you do not agree to these terms, you should not use this app."
    },
    {
        title: "2. User Accounts",
        details: "To submit ideas, vote on ideas, report content, or download ideas, users must sign in using Google authentication. During sign-in, users are required to provide their date of birth, occupation, and current city. Users must ensure that their account information is accurate and up to date."
    },
    {
        title: "3. Submitting Ideas",
        details: "Users can submit ideas by selecting a category, providing a title and short description, and uploading an optional image. Submissions must not violate any laws, contain offensive or inappropriate content, or infringe on the rights of others. We reserve the right to remove any submissions that violate these terms. Once submitted, an idea will be displayed on the platform's landing page, including the idea’s serial number, category name, title, and vote count."
    },
    {
        title: "4. Voting on Ideas",
        details: "Users can vote on up to 10 ideas per month. Voting is intended to highlight popular ideas and does not guarantee implementation. Users are not allowed to vote on their own ideas. Voting abuse, such as using multiple accounts or manipulating votes, is strictly prohibited."
    },
    {
        title: "5. Filtering Ideas",
        details:"Users can filter ideas by selecting one of the predefined categories such as Education, Environment, Healthcare, etc. Alternatively, users can view the top 3 or top 10 most voted ideas. The platform also displays the total number of submitted ideas and the total votes cast on the landing page."
    },
    {
        title: "6. Downloading Ideas",
        details: "Users have the ability to download submitted ideas in CSV format. The CSV file will contain information such as the idea serial number, category name, title, and vote count for each idea. This functionality is provided for personal use only, and unauthorized commercial or improper use of the data is prohibited."
    },
    {
        title: "7. Sharing Ideas",
        details: "Users can share ideas, including their own and others, through various social media platforms such as Facebook, Messenger, Instagram, Telegram, WhatsApp, LinkedIn, X (formerly Twitter), mail, and by copying a link. Users cannot vote on their own submitted ideas but can still share them via these platforms."
    },
    {
        title: "8. Reporting Content",
        details: "If you find content that violates these terms or is deemed inappropriate, you may report it through the platform. Our team will review the reported content and take necessary actions, which may include removal."
    },
    {
        title: "9. User Conduct",
        details: "By using this app, you agree not to:",
        listData: [
            "Post or submit content that is unlawful, harmful, defamatory, or discriminatory.",
            "Misuse the voting system by manipulating votes or creating multiple accounts.",
            "Use the app for unauthorized commercial purposes."
        ]
    },
    {
        title: "10. Limitation of Liability",
        details: "We are not liable for any damages arising from the use of this app, including but not limited to, loss of data, profits, or any indirect damages."
    },
    {
        title: "11. Changes to Terms",
        details: "We reserve the right to update these Terms & Conditions at any time. Users will be notified of significant changes. Continued use of the app after such changes will be considered acceptance of the new terms."
    },
    {
        title: "12. Governing Law",
        details: "These terms are governed by the laws of Bangladesh. Any disputes will be resolved in accordance with the laws of Bangladesh."
    }
];
