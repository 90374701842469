import React, { useEffect, useState } from 'react';
import Leaderboard from '../components/Leaderboard';
import '../components/css/Home.css'; // Create the relevant CSS file for styling
import { useDispatch, useSelector } from 'react-redux';
import { FetchIdeaCount } from '../actions/IdeaActions';
import WinModal from '../components/Modal/WinModal';

const Home = () => {
  const dispatch = useDispatch();
  const IdeasData = useSelector((state) => state.IdeasCountInfo);
  let { CountIdeasloading, CountIdeasError, CountIdeas = [] } = IdeasData;

  const [totalIdeas, setTotalIdeas] = useState(0);
  const [totalVotes, setTotalVotes] = useState(0);

  const [modalShow, setModalShow] = useState(false);

  const roundDownToNearest = (number, round) => {
    return Math.floor(number / round) * round;
  }

  const closeModal = () => {
    setModalShow(false);
  };


  useEffect(() => {
    if (totalIdeas == 0 || totalVotes == 0)
      dispatch(FetchIdeaCount(1, 1, ""));
    if (CountIdeas.totalIdeas > 0) {
      setTotalIdeas(CountIdeas.totalIdeas);
      setTotalVotes(CountIdeas.totalVoteCast.totalVotes);
    }
  }, [CountIdeas.totalIdeas])
  return (
    <>
      {modalShow &&
        <WinModal modalShow={modalShow} setModalShow={closeModal} />
      }
      <div className='main_div'>
        <div className="hero_frame-1171277460">
          <div className="hero_frame-1171275578">
            <div className="hero_shape-the-future-of-bangladesh">
              Shape the future of Bangladesh
            </div>
            <div
              className="hero_your-ideas-your-voice-your-country-share-your-vision-for-a-better-bangladesh-and-vote-on-the-ideas-that-can-make-it-happen"
            >
              Your ideas, your voice, your country. Share your vision for a better
              <br />
              Bangladesh and vote on the ideas that can make it happen.
            </div>
          </div>
          <div className="hero_frame-1171275395">
            <div className="hero_frame-1171277425" onClick={() => setModalShow(true)}>
              <div className="hero_frame-120" onClick={() => setModalShow(true)}>
                <div className="hero_total-ideas-submitted">Total Ideas Submitted</div>
                <div className="hero__500">{totalIdeas && totalIdeas - 1}+</div>
              </div>
              <div className="hero_frame-121_mobile" onClick={() => setModalShow(true)}>
                <img src='/home/win.gif' className="right-most-img_mobile" alt="Image" />
              </div>
              <div className="hero_divider"></div>
              <div className="hero_frame-121">
                <div className="hero_total-votes-cast">Total Votes Cast</div>
                <div className="hero__5000">{totalVotes && totalVotes - 1}+</div>
              </div>

              <div className="hero_frame-121 third-div-displaynone-in-mobile" onClick={() => setModalShow(true)}>
                <img src='/home/win.gif' className="right-most-img" alt="Image" />
              </div>
            </div>
          </div>
        </div>

        <Leaderboard />
      </div>
    </>
  );
}

export default Home;


{/* 
.hero_divider {
  background: rgba(46, 58, 89, 0.25);
  flex-shrink: 0;
  width: 1px;
  height: 50px;
}
  
  .hero_divider {
    background: rgba(46, 58, 89, 0.25);
    flex-shrink: 0;
    width: 1px;
    height: 36px;
    position: relative;
  }
*/}