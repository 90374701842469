import Axios from 'axios';
import { JOIN_US_POST_FAIL, JOIN_US_POST_REQUEST, JOIN_US_POST_SUCCESS } from "../constants/JoinUsConstants";

export const JoinUsPost = (formData) => async (dispatch) => {
  dispatch({ type: JOIN_US_POST_REQUEST });

  try {
    for (let [key, value] of formData.entries()) {
        console.log(key, value);
      }
    
    // Send FormData directly, Axios will handle the multipart/form-data headers
    const { data } = await Axios.post(`${process.env.REACT_APP_BACKEND}/join-us`, formData);

    dispatch({ type: JOIN_US_POST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: JOIN_US_POST_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};
