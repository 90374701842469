import { USER_GOOGLE_INFO_FAIL, USER_GOOGLE_INFO_REQUEST, USER_GOOGLE_INFO_SUCCESS, USER_INFO_FAIL, USER_INFO_REQUEST, USER_INFO_SUCCESS, USER_VOTE_COUNT_FAIL, USER_VOTE_COUNT_REQUEST, USER_VOTE_COUNT_SUCCESS } from "../constants/UserConstants";

export const UserGoogleInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_GOOGLE_INFO_REQUEST:
      return { loading: true };
    case USER_GOOGLE_INFO_SUCCESS:
      return { loading: false, UserGoogleInfo: action.payload };
    case USER_GOOGLE_INFO_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const userInfoReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_INFO_REQUEST:
        return { loading: true };
      case USER_INFO_SUCCESS:
        return { loading: false, userInfo: action.payload };
      case USER_INFO_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };

  export const userTotalVoteCountReducer = (state = {}, action) => {
    switch (action.type) {
      case USER_VOTE_COUNT_REQUEST:
        return { loadingUserTotalVoteCount: true };
      case USER_VOTE_COUNT_SUCCESS:
        return { loadingUserTotalVoteCount: false, userInfoVoteCount: action.payload };
      case USER_VOTE_COUNT_FAIL:
        return { loadingUserTotalVoteCount: false, errorUserTotalVoteCount: action.payload };
      default:
        return state;
    }
  };
