export const FETCH_IDEA_REQUEST = 'FETCH_IDEA_REQUEST';
export const FETCH_IDEA_SUCCESS = 'FETCH_IDEA_SUCCESS';
export const FETCH_IDEA_FAIL = 'FETCH_IDEA_FAIL';

export const FETCH_TOP_IDEA_REQUEST = 'FETCH_TOP_IDEA_REQUEST';
export const FETCH_TOP_IDEA_SUCCESS = 'FETCH_TOP_IDEA_SUCCESS';
export const FETCH_TOP_IDEA_FAIL = 'FETCH_TOP_IDEA_FAIL';



export const FETCH_IDEA_COUNT_REQUEST = 'FETCH_IDEA_COUNT_REQUEST';
export const FETCH_IDEA_COUNT_SUCCESS = 'FETCH_IDEA_COUNT_SUCCESS';
export const FETCH_IDEA_COUNT_FAIL = 'FETCH_IDEA_COUNT_FAIL';



export const FETCH_MY_IDEA_REQUEST = 'FETCH_MY_IDEA_REQUEST';
export const FETCH_MY_IDEA_SUCCESS = 'FETCH_MY_IDEA_SUCCESS';
export const FETCH_MY_IDEA_FAIL = 'FETCH_MY_IDEA_FAIL';