// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mainHead{
  color: #2e3a59;
  text-align: center;
  font-family: "Inter-Medium", sans-serif;
  font-size: 48px;
  line-height: 120%;
  font-weight: 500;
  position: relative;
}`, "",{"version":3,"sources":["webpack://./src/components/GiveAFeedback/giveAFeedback.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;EAClB,uCAAuC;EACvC,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,kBAAkB;AACpB","sourcesContent":[".mainHead{\n  color: #2e3a59;\n  text-align: center;\n  font-family: \"Inter-Medium\", sans-serif;\n  font-size: 48px;\n  line-height: 120%;\n  font-weight: 500;\n  position: relative;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
