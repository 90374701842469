// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobile-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1px 5px;
    background-color: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 40px;
    z-index: 1000;
    margin-bottom: 100px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .back-button {
    position: absolute;
    left: 15px;
    bottom: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: #000000;
    cursor: pointer;
  }
  
  .header-title {
    color: #000000;
    font-size: 20px;
    font-family: "Inter-Bold", sans-serif;
    text-align: center;
    margin: 0;
  }
  
  /* Hide the header on screens larger than tablet size (1024px) */
  @media (min-width: 770px) {
    .mobile-header {
      display: none;
    }
  }
  `, "",{"version":3,"sources":["webpack://./src/components/css/MobileHeader.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,yBAAyB;IACzB,eAAe;IACf,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,aAAa;IACb,oBAAoB;IACpB,0CAA0C;EAC5C;;EAEA;IACE,kBAAkB;IAClB,UAAU;IACV,YAAY;IACZ,gBAAgB;IAChB,YAAY;IACZ,eAAe;IACf,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,qCAAqC;IACrC,kBAAkB;IAClB,SAAS;EACX;;EAEA,gEAAgE;EAChE;IACE;MACE,aAAa;IACf;EACF","sourcesContent":[".mobile-header {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 1px 5px;\n    background-color: #ffffff;\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 40px;\n    z-index: 1000;\n    margin-bottom: 100px;\n    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);\n  }\n  \n  .back-button {\n    position: absolute;\n    left: 15px;\n    bottom: 10px;\n    background: none;\n    border: none;\n    font-size: 24px;\n    color: #000000;\n    cursor: pointer;\n  }\n  \n  .header-title {\n    color: #000000;\n    font-size: 20px;\n    font-family: \"Inter-Bold\", sans-serif;\n    text-align: center;\n    margin: 0;\n  }\n  \n  /* Hide the header on screens larger than tablet size (1024px) */\n  @media (min-width: 770px) {\n    .mobile-header {\n      display: none;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
