const TermsAndConditionsHeader = () => {
    return(
        <div className="head_main">
        <div className="head_main_title">Terms &amp; Conditions</div>
        <div
          className="head_details"
        >
          These Terms &amp; Conditions govern the use of the &#039;Poriborton
          Chai&#039; app, including submitting ideas, voting, sharing, and reporting.
          By using the app, users agree to follow these guidelines.
        </div>
      </div>
      
    )
}

export default TermsAndConditionsHeader;