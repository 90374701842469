import { useState } from "react";
import { Link } from "react-router-dom";
import '../css/Leaderboard.css';
import { useNavigate } from 'react-router-dom';
const MobileVersionPitchAndProfilebtn = () => {
    const [userInfo, setUserInfo] = useState(JSON.parse(localStorage.getItem('PoribortonchaiUserData')));
    const navigate = useNavigate();
      const handleSignIn = () => {
    // Simplified mobile detection
    const isMobile = /android|iphone|ipad|ipod|windows phone/i.test(
      navigator.userAgent || navigator.vendor || window.opera
    );

  
    const signInUrl = `${window.location.origin}/login`;
  
    if (isMobile) {
      // Open in a new tab on mobile devices
      window.open(signInUrl, '_blank', 'noopener,noreferrer');
    } else {
      // Use react-router's navigate function for desktop
      navigate(`/login`);
    }
  }
    return(
        <div className='mobile_pitch-insertion'>
        {userInfo ?
          <div className='mbl_side_by_side'>
            <Link to="/my-ideas" className="pitch-button_signed_in_pitch-button_mbl" style={{ backgroundColor: "#ffffff" }}>
              <div class="frame-1171277485">
                <img class="rectangle-303" src={userInfo.profilePicture ? userInfo.profilePicture : '/UserDetails/userNotFound.png'} />
                <div class="profile">Profile</div>
              </div>
            </Link>
            <div>
              <Link to="/pitch" className="pitch-button_signed_in_pitch-button_mbl">Pitch my idea</Link>
            </div>
          </div>
          :
          <div
              onClick={()=>handleSignIn()}
              className="pitch-button_signed_in_pitch-button_mbl">
              Pitch my idea
            </div>
        }
      </div>
    )
}

export default MobileVersionPitchAndProfilebtn;