import React, { useState } from 'react';
import { termsAndConditionsData } from './data'; // Adjust the path according to your file structure
import "./termsAndConstions.css"
import TermsAndConditionsHeader from './TermsAndConditionsHeader';

const TermsAndConditionsMain = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const handleToggle = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (
        <div>
            <TermsAndConditionsHeader/>
            <div className="terms-container">
            {termsAndConditionsData.map((term, index) => (
                <div key={index} className="term-item">
                <div className="term-title" onClick={() => handleToggle(index)}>
                    {activeIndex === index? <h4 style={{color: "#4548e3"}}>{term.title}</h4>: <h4>{term.title}</h4>}
                    
                    <span className='arrow-terms'>{activeIndex === index ? 
                        <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.8327 1.08203L6.99935 6.91536L1.16602 1.08203" stroke="#4548E3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    : <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.5 1L8.5 8L1.5 15" stroke="#4548E3" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    }</span>
                </div>
                {activeIndex === index && (
                    <div className="term-details">
                        {/* <p className='term-title_details'>{term.title}</p> */}
                    
                            <>
                            <p className='terms_details_main'>{term.details}</p>
                            {term.listData && 
                                term.listData.map((listData, indexListData) => (
                                    <>
                                        <ul className='terms_details_main' style={{lineHeight: "5px"}} key={indexListData}>
                                            <li>{listData}</li>
                                        </ul>
                                    </>
                                ))
                            }
                            </>
                        
                        
                    
                    </div>
                )}
            </div>
            ))}
        </div>
        </div>

    );
};

export default TermsAndConditionsMain;
