import React, { useEffect, useState } from 'react';
import { Box, Modal, Button, Typography } from '@mui/material';

const InformingUser = (props) => {
  const [open, setOpen] = useState(true);

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    // Close the modal and set modalShow to false in the parent component
    setOpen(false);
    props.setModalShow(false);
  };

  // Modal Styles
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    border: "none",
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>

          <Typography id="modal-description" sx={{ mt: 2 }}>
            {props.modalContents}
          </Typography>
          <Button onClick={handleClose}  variant="contained"
            sx={{ bgcolor: '#4548e3', width: '100%', mt: 2 }}>
                OK
          </Button>
        </Box>
      </Modal>
    </div>
  );
};

export default InformingUser;
