import Axios from 'axios';
import { FEEDBACK_POST_FAIL, FEEDBACK_POST_REQUEST, FEEDBACK_POST_SUCCESS } from '../constants/FeedbackConstants';


export const FeedBackPost = (category, description) => async (dispatch) => {
  dispatch({ type: FEEDBACK_POST_REQUEST });

  try {
    // Send FormData directly, Axios will handle the multipart/form-data headers
    const { data } = await Axios.post(`${process.env.REACT_APP_BACKEND}/feedback`, {category, description});

    dispatch({ type: FEEDBACK_POST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FEEDBACK_POST_FAIL,
      payload: error.response && error.response.data.message
        ? error.response.data.message
        : error.message,
    });
  }
};
