import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const SignInPage = () => {
  const location = useLocation();

  // Function to extract the query parameters
  const getQueryParams = () => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get('idea');
  };

  useEffect(() => {
    const idea = getQueryParams(); // Get the 'idea' parameter from the URL

    if (idea) {
      // Use the idea parameter in your redirection
      window.location.href = `${process.env.REACT_APP_BACKEND}/auth/google?idea=${idea}`;
    } else {
      // If there's no idea parameter, just redirect to Google OAuth
      window.location.href = `${process.env.REACT_APP_BACKEND}/auth/google`;
    }
  }, [location]);

  return null; 
};

export default SignInPage;