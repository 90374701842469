
import Avatar from '@mui/material/Avatar';
import { throttle } from 'lodash';
import React, { useCallback } from 'react';

const VotersList = (Props) => {

  // Throttle the Avatar rendering with lodash throttle
  const throttledAvatar = useCallback(throttle(() => {
    return (
      <Avatar
        src={Props.profilePicture || '/UserDetails/userNotFound.png'}
        loading="lazy"
        sx={{
          bg: '/UserDetails/userNotFound.png',
          width: 36,
          display: "flex",
          height: 36,
          marginLeft: "-15px",
          padding: 0, // Ensure no padding
        }}
      >
      </Avatar>
    );
  }, 9000000000000000), []);

  return (
    <div style={{ display: 'flex', gap: '0px', alignItems: 'center' }}>
      {/* Ensuring no gaps between Avatar components */}
      {throttledAvatar()}
    </div>
  )
}

export default VotersList;