import React from 'react';
import { Box, Modal, Typography } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import SocialsIdeaIconsForModal from '../IdeaDetails/SocialsIdeaIconsForModal';
import "./css/IdeaSubmissionModal.css";

const WinModal = ({ modalShow, setModalShow, idea_id }) => {
  const navigate = useNavigate();

  // Function to handle the "Pitch My Idea" button
  const handlePitchMyIdea = () => {
    setModalShow(false); // Close modal
    navigate('/pitch'); // Navigate to the pitch page
  };

  // Function to handle the "Vote Ideas" button
  const handleViewAllIdea = () => {
    setModalShow(false); // Close modal
    navigate("/"); // Navigate to the home page
  };

  const style = {
    border: "none",
  };

  return (
    <Modal
      open={modalShow}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      onClose={() => setModalShow(false)} // Close the modal when clicking outside
    >
      <Box sx={style}>
        <div className="idea-modal-container">
          <div className="modal-padding-win">
          <img src="/home/win.png" alt="Thank You" className="idea-modal-image" />
          <p className="idea-modal-title">Monthly Idea Contest!</p>

          <div className="your-idea-has-been-pitched-main">
            <div className="your-idea-has-been-pitched">
              Top 3 Ideas with the Most Votes Win Exclusive Rewards
            </div>
          </div>

          <Typography className="idea-modal-message">
            Pitch your innovative ideas or vote for others and help shape the future of Bangladesh. Every month, the top 3 ideas with the most votes will be crowned winners!
          </Typography>

          {/* <SocialsIdeaIconsForModal ideaId={idea_id} /> */}

          <div className="idea-modal-button-group">
            <button
              className="idea-modal-back-btn"
             
              onClick={handlePitchMyIdea}
            >
              Pitch My Idea
            </button>
            <button
              className="idea-modal-view-btn"
              onClick={handleViewAllIdea}
            >
              Vote Ideas
            </button>
          </div>
 
          </div>
          <div>
          <div className="modal-bottom-container">
              {/* Left div with image and text */}
              <div className="left-modal-bottom">
                <Link to={"https://www.facebook.com/bdtimetraveler/"} target={"_blank"} style={{textDecoration: "none"}}>
                  <img src="/time_traveller.png" alt="Sponsored by Time Travellers" className="sponsor-image-modal-bottom" />
                </Link>
                <span>Sponsored by <Link to={"https://www.facebook.com/bdtimetraveler/"} target={"_blank"} style={{textDecoration: "none"}}>Time Travellers</Link></span>
              </div>

              {/* Right div for previous winner text */}
              <Link to={"https://www.facebook.com/bdtimetraveler/"} target={"_blank"} style={{textDecoration: "none"}}>
              <div className="right-modal-bottom">
                <span>Previous Winner
                <svg width="18" height="9" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.0893 6.31429C0.756504 6.36011 0.5 6.64961 0.5 6.99991C0.5 7.38205 0.80526 7.69184 1.18182 7.69184L13.1666 7.69184L8.83724 12.0678L8.77107 12.1453C8.57247 12.4157 8.59369 12.8002 8.83525 13.0463C9.10096 13.3171 9.53267 13.318 9.79948 13.0483L15.2897 7.49966C15.3225 7.46786 15.3521 7.43279 15.378 7.39496C15.5639 7.12475 15.5377 6.74993 15.2994 6.50922L9.79944 0.951611L9.72283 0.884782C9.45548 0.684378 9.07675 0.707538 8.83521 0.953718C8.56952 1.22452 8.57045 1.66262 8.83729 1.93226L13.1677 6.30797L1.18182 6.30797L1.0893 6.31429Z" fill="#4548E3"/>
                  </svg>

                </span>
              </div>
              </Link>
            </div>
          </div>
        </div>


      </Box>
    </Modal>
  );
};

export default WinModal;
