
export const USER_GOOGLE_INFO_REQUEST = 'USER_GOOGLE_INFO_REQUEST';
export const USER_GOOGLE_INFO_SUCCESS = 'USER_GOOGLE_INFO_SUCCESS';
export const USER_GOOGLE_INFO_FAIL = 'USER_GOOGLE_INFO_FAIL';

export const USER_INFO_REQUEST = 'USER_INFO_REQUEST';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAIL = 'USER_INFO_FAIL';

export const USER_VOTE_COUNT_REQUEST = 'USER_VOTE_COUNT_REQUEST';
export const USER_VOTE_COUNT_SUCCESS = 'USER_VOTE_COUNT_SUCCESS';
export const USER_VOTE_COUNT_FAIL = 'USER_VOTE_COUNT_FAIL';
