import { ALL_CETEGORY_FAIL, ALL_CETEGORY_REQUEST, ALL_CETEGORY_SUCCESS } from "../constants/CategoryConstants";

export const AllCaregoryInfoReducer = (state = {}, action) => {
    switch (action.type) {
      case ALL_CETEGORY_REQUEST:
        return { loading: true };
      case ALL_CETEGORY_SUCCESS:
        return { loading: false, allCategoryData: action.payload };
      case ALL_CETEGORY_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };