import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga';

// Initialize Google Analytics with your Measurement ID
ReactGA.initialize('G-0YHLJ109S5',{ debug: true }); // Replace with your Measurement ID

// Track the initial page load
ReactGA.pageview(window.location.pathname + window.location.search);

// Check the current URL and redirect if necessary
if (window.location.hostname === 'poribortonchai.com') {
     window.location.replace('https://www.poribortonchai.com/');
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
     <HelmetProvider>
          <Provider store={store}>
               <App />
          </Provider>
     </HelmetProvider>
   
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
