import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import './css/PitchIdea.css';
import InformingUser from './Modal/Inform';
import { Select, MenuItem, Box, Typography, FormControl, InputLabel } from '@mui/material';
import IdeaSubmissionModal from './Modal/IdeaSubmissionModal';
import { ToastContainer, toast } from 'react-toastify';
import ReactGA from 'react-ga'; 

const PitchIdea = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const userInfo = JSON.parse(localStorage.getItem('PoribortonchaiUserData'));
  const[modalShow, setModalshow] = useState(false);
  const[errorShow, setErrorshow] = useState(false);
  const[modalContent, setModalContent] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [titleLength, setTitleLength] = useState(0);

  const [descriptionLength, setDescriptionLength] = useState(0);


  const[submittedModalShow, setSubmittedModalShow] = useState(false);
  const[submittedModalContent, setSubmittedModalContent] = useState(false);

  const navigate = useNavigate();  // Initialize useNavigate for redirection

  // Fetch categories from the backend when the component mounts
  useEffect(() => {
    ReactGA.initialize('G-0YHLJ109S5'); 
    if(!userInfo){
      navigate('/login');
    }
    fetch(`${process.env.REACT_APP_BACKEND}/all/categories`) // Call your backend API to fetch categories
      .then((response) => response.json())
      .then((data) => setCategories(data))
      .catch((error) => console.error('Error fetching categories:', error));

      //jfkhajkdhfjkds
      fetch(`${process.env.REACT_APP_BACKEND}/user/get-user-data`, {
        credentials: 'include',
      }).then((response) => {
        return response.json();
      }).then((data) => {
        try{
          const { userProfile } = data; // Get user profile data
          if(!userProfile) {
            navigate('/login');
          }
          const { name, occupation, currentCity, dob } = userProfile;
    
          // Check if any required fields are missing
          if (!name || !occupation || !currentCity || !dob) {
            setErrorshow(true);
              setTimeout(() => {
                navigate('/profile?page=pitch'); // Redirect to profile page for editing
                return;
              }, 2500);
            }
        }catch(error){
          return error;
        }
   
        })
        
  }, []);

  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
    const previewUrl = URL.createObjectURL(e.target.files[0]);
    setImagePreview(previewUrl);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0]; // Get the dropped file
    setImage(file); // Set the dropped file as the selected image
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    if (imagePreview) {
      URL.revokeObjectURL(imagePreview);
    }
  
    ReactGA.pageview(window.location.pathname + window.location.search);
    ReactGA.event({
      category: 'Idea',
      action: 'A new Idea Pitched',
      label: "A new idea pitched",
    });
    // Check if user is logged in
    if (!userInfo || !userInfo._id) {
      setModalshow(true);
      setModalContent("You must be logged in to submit an idea");
      setIsSubmitting(false);
      navigate('/login');  // Redirect to login if user is not found
      return;
    }
  
    // Fetch the user profile data to check if the user has completed their profile
    fetch(`${process.env.REACT_APP_BACKEND}/user/get-user-data`, {
      credentials: 'include',
    })
      .then((response) => {
        if (response.status === 401) {
          setModalshow(true);
          setModalContent("Your session has expired. Please log in again.");
          navigate('/login');
          throw new Error('Unauthorized');
        }
        if (!response.ok) {
          throw new Error('Failed to fetch user data');
        }
        return response.json();
      })
      .then((data) => {
        const { userProfile } = data; // Get user profile data
        const { name, occupation, currentCity, dob } = userProfile;
  
        // Check if any required fields are missing
        if (!name || !occupation || !currentCity || !dob) {
          setModalshow(true);
          setModalContent("You must complete your profile before submitting an idea.");
          setIsSubmitting(false);
          navigate('/profile'); // Redirect to profile page for editing
          return;
        }
  
        // If the profile is complete, proceed with submitting the idea
        const { _id } = userInfo; // Extract the user ID from userInfo
        const formData = new FormData();
        formData.append('categoryId', selectedCategory);
        formData.append('title', title);
        formData.append('description', description);
        formData.append('userId', _id); // Attach user ID to the form data
        if (image) {
          formData.append('image', image); // If image is selected, append it to formData
        }
  
        // Send a POST request to submit the form data
        return fetch(`${process.env.REACT_APP_BACKEND}/user/idea/upload`, {
          method: 'POST',
          body: formData,
          credentials: 'include', // Include cookies for authentication
        });
      })
      .then((response) => {
        if (response.status === 401) {
          setModalshow(true);
          setModalContent("Your session has expired. Please log in again.");
          navigate('/login');  // Redirect to login page
          throw new Error('Unauthorized');
        }
        

        if(response.status === 403) {
          setModalshow(true);
          setModalContent("You have reached your Idea limit for this month.");
          throw new Error('You have reached your Idea limit for this month.');
        }

        if(response.status === 400) {
          setModalshow(true);
          setModalContent("File size too big.");
          throw new Error('File size too big');
        }

        if(response.status === 413 || response.status === 500 ) {
          setModalshow(true);
          setModalContent("File size too big.");
          throw new Error('File size too big');
        }
        console.log("checking response status: " + response);
        if (!response.ok) {
          setModalshow(true);
          setModalContent("File size too big");
          throw new Error('Failed to submit the idea');
        }
        return response.json(); // Parse the response JSON
      })
      .then((data) => {
        setSubmittedModalShow(true);
        setSubmittedModalContent(`${data.idea._id}`);
        setIsSubmitting(false);
      })
      .catch((error) => {
        console.log("error.name = " + error.name);
        console.log("error.message = " + error.message);
        if (error.name === 'TypeError') {
          // This will catch network-related issues like CORS, server down, etc.
          setModalshow(true);
          setModalContent("File size too big");
        } else if (error.message === 'Unauthorized') {
          setModalshow(true);
          setModalContent("Your session has expired. Please log in again.");
          navigate('/login');
        } else if (error.message.includes('File size too big')) {
          setModalshow(true);
          setModalContent("File size too big. Max. file size limit: 1.5mb");
        } else if(error.message.includes("You have reached your Idea limit for this month")){
          setModalshow(true);
          setModalContent("You have reached your Idea limit for this month.");
        }else{
          setModalshow(true);
          setModalContent("Something went wrong!.");
        }
        setIsSubmitting(false);
      });
      
  };

  const setTitleFunc = (e) => {
    const inputValue = e.target.value;
  
    // Set the title and the length of the input directly based on the current value
    setTitle(inputValue);
    setTitleLength(inputValue.length); // Update the titleLength to match the input length
  };
  

  const setDiscriptionFunc = (e) => {
    const inputValueOfDesc = e.target.value;
  
    // Set the title and the length of the input directly based on the current value
    setDescription(inputValueOfDesc);
    setDescriptionLength(inputValueOfDesc.length); // Update the titleLength to match the input length
  };
  
  
  return (
    <>
    {submittedModalShow && <IdeaSubmissionModal idea_id = {submittedModalContent}/>}
    {modalShow && <InformingUser 
      modalContents={modalContent} 
      setModalShow={setModalshow} 
      /> 
    }
    <div className="pitch-idea-container">
      {errorShow && <InformingUser 
        modalContents="Please Fill up the additional information to pitch"
        setModalShow={setModalshow} 
        />}
      
      <form className="pitch-idea-form" onSubmit={handleSubmit}>
      <div class="pitch-my-idea">Pitch my idea</div>
        <div className="form-group">
          <div className="category-label-pitch-ideas-insert">Category*</div>
          <FormControl fullWidth variant="outlined" sx={{ minWidth: 240, maxHeight: 40 }}>
            <Select
            className='txtClassPitchIdeaInsert'
              labelId="category-select-label"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              required
              displayEmpty
              sx={{
                height: '45px',
                padding: '0px',
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 300, // Control max height of the dropdown list
                  },
                },
              }}
            >
              <MenuItem value="" disabled>
                <p>Select Category</p>
              </MenuItem>
              {categories.map((category) => (
                <MenuItem key={category._id} value={category._id}>
                  <Box display="flex" alignItems="center">
                    <Box
                      component="img"
                      src={`/category_images/${category.name.toLowerCase()}1.png`}
                      alt={category.name}
                      sx={{
                        width: '30px',
                        padding: '8px',
                        height: '30px',
                        marginRight: '10px',
                        objectFit: 'contain',
                      }}
                    />
                    <Typography>{category.name}</Typography>
                  </Box>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        {/* Title Input */}
        <div className="form-group" style={{marginTop: "10px"}}>
          <label htmlFor="title" className="category-label-pitch-ideas-insert">Title*</label>
          <input
            id="title"
            type="text"
            className='txtClassPitchIdeaInsert'
            value={title}
            onChange={(e) => setTitleFunc(e)}
            required
            maxLength={500}
          />
          <p className='length_of_pitch_txt'>{titleLength}/500</p>
        </div>

        {/* Description Input */}
        <div className="form-group" style={{marginTop: "-35px"}}>
          <label htmlFor="description" className="category-label-pitch-ideas-insert">Description*</label>
          <textarea
            id="description"
            className='Description_Insertion_Pitch'
            value={description}
            onChange={(e) => setDiscriptionFunc(e)}
            required
            maxLength={10000}
          />
            <p className='length_of_pitch_txt'>{descriptionLength}/10000</p>
        </div>

      {!image ?
        <>
          {/* Image Upload */}
          <div className="form-group" style={{marginTop: "-35px"}}>
          <label htmlFor="image" className="category-label-pitch-ideas-insert">Upload Photo</label>
          <div className="frame-1171276623" 
                onDrop={handleDrop}
                onDragOver={handleDragOver}>
            <div className="frame-1171276668">
              <div className="cloud-computing-1">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_591_942)">
                <path d="M29.9945 13.2332C28.3307 6.60498 21.6088 2.58059 14.9806 4.24442C9.80078 5.54471 6.03968 10.0215 5.65216 15.3479C1.97431 15.9544 -0.515536 19.4275 0.0909836 23.1054C0.630151 26.375 3.46329 28.7696 6.77704 28.7564H12.4014V26.5067H6.77704C4.29205 26.5067 2.27753 24.4921 2.27753 22.0071C2.27753 19.5222 4.29205 17.5076 6.77704 17.5076C7.39832 17.5076 7.90192 17.004 7.90192 16.3828C7.89629 10.7915 12.4244 6.2543 18.0157 6.24874C22.8557 6.24389 27.0222 9.66563 27.9585 14.4142C28.0509 14.8884 28.4357 15.2506 28.9146 15.3141C31.9899 15.7521 34.1278 18.6 33.6899 21.6752C33.2967 24.4367 30.939 26.4927 28.1497 26.5067H23.6502V28.7564H28.1497C32.4985 28.7433 36.0132 25.2072 35.9999 20.8584C35.989 17.2384 33.5111 14.0922 29.9945 13.2332Z" fill="#2E3A59"/>
                <path d="M17.228 17.834L12.7285 22.3335L14.3146 23.9195L16.9018 21.3436V32.1311H19.1516V21.3436L21.7275 23.9195L23.3136 22.3335L18.8141 17.834C18.3753 17.3978 17.6668 17.3978 17.228 17.834Z" fill="#2E3A59"/>
                </g>
                <defs>
                <clipPath id="clip0_591_942">
                <rect width="36" height="36" fill="white"/>
                </clipPath>
                </defs>
              </svg>
              </div>
              <div className="frame-1171276669">
                <div className="frame-1171276667">
                  <div className="drag-drop-or-browser-file">
                      <span className="drag-drop-or-browser-file-span2">
                          <input
                            id="image"
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            style={{ display: 'none' }} // Hide the actual input
                          />
                          <label htmlFor="image" className="drag-drop-or-browser-file-span2">
                            <span className="drag-drop-or-browser-file-span">
                                Drag &amp; Drop or
                              </span> <span style={{textDecoration: "underline"}}> Browse File </span>
                              {/* image && <p>Selected file: {image.name}</p>*/}
                          </label>
                        </span>
                  </div>
                  <div className="max-file-size-limit-2-mb">(Max. file size limit: 1.5mb)</div>
                </div>
              </div> 
            </div>
          </div>
        </div>
        </>: 
        <>
        <div className="upload_have_img-frame-1171276623" style={{marginTop: "-10px"}}>
          <div className="upload_have_img-frame-1171277540">
            <img className="upload_have_img-rectangle-318" src={imagePreview} />
            <div className="upload_have_img-frame-1171276667">
              <div className="upload_have_img-frame-1171277541">
                <div className="image-1-jpg">{image.name}</div>
                <div className="upload_have_img-_1-96-mb"></div>
              </div>
            </div>
          </div>
          <span className="drag-drop-or-browser-file-span3">
            <input
              id="image"
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              style={{ display: 'none' }} // Hide the actual input
            />
            <label htmlFor="image" className="drag-drop-or-browser-file-span3">
              <span className="drag-drop-or-browser-file-span">
                </span> <span style={{textDecoration: "underline"}}> Upload Again </span>
                {/* image && <p>Selected file: {image.name}</p>*/}
            </label>
          </span>
        </div>

        </>
      }

        {/* Submit Button */}
        <div class="frame-1171275240">
          <div class="pitch">
            <button type="submit" disabled={isSubmitting} style={{display: "hidden"}}>
            {isSubmitting ? 'Submitting...' : 'Pitch'}
          </button>
          </div>
        </div>
      </form>
      
    </div>
    </>
  );
};

export default PitchIdea;
