const PrivacyPolicyHeader = () => {
    return(
        <div className="head_main">
        <div className="head_main_title">Privacy Policy</div>
        <div
          className="head_details"
        >
         This Privacy Policy explains how 'Poriborton Chai' collects, uses, and protects user data, including sign-in details and submitted content
        </div>
      </div>
    )
}

export default PrivacyPolicyHeader;