import { JOIN_US_POST_FAIL, JOIN_US_POST_REQUEST, JOIN_US_POST_SUCCESS } from "../constants/JoinUsConstants";

export const JoinUsReducer = (state = {}, action) => {
    switch (action.type) {
      case JOIN_US_POST_REQUEST:
        return { loading: true };
      case JOIN_US_POST_SUCCESS:
        return { loading: false, joinUsInfo: action.payload };
      case JOIN_US_POST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
  };