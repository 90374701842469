import Axios from 'axios';
import { USER_GOOGLE_INFO_FAIL, USER_GOOGLE_INFO_REQUEST, USER_GOOGLE_INFO_SUCCESS, USER_INFO_FAIL, USER_INFO_REQUEST, USER_INFO_SUCCESS, USER_VOTE_COUNT_FAIL, USER_VOTE_COUNT_REQUEST, USER_VOTE_COUNT_SUCCESS } from '../constants/UserConstants';


export const userInfoGoogleRedirectAction = () => async (dispatch) => {
    dispatch({ type: USER_GOOGLE_INFO_REQUEST});
    try {
      const { data } = await Axios.get(`${process.env.REACT_APP_BACKEND}/auth/google`, {
        withCredentials: true // This allows cookies to be sent
        });
      localStorage.setItem('PoribortonchaiUserData', JSON.stringify(data));
      dispatch({ type: USER_GOOGLE_INFO_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: USER_GOOGLE_INFO_FAIL ,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
};

export const userInfoAction = () => async (dispatch) => {
    dispatch({ type: USER_INFO_REQUEST});
    try {
      const { data } = await Axios.get(`${process.env.REACT_APP_BACKEND}/auth/current_user`, { withCredentials: true });
      dispatch({ type: USER_INFO_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: USER_INFO_FAIL ,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
};

export const userVoteIdeaCount = () => async (dispatch) => {
  dispatch({ type: USER_VOTE_COUNT_REQUEST});
  try {
    const userInfo = JSON.parse(localStorage.getItem("PoribortonchaiUserData")); // Parse the JSON string


    const { data } = await Axios.get(`${process.env.REACT_APP_BACKEND}/user/idea/vote/count/unique`, {
      params: {
        userId: userInfo._id // Send userId as a query parameter
      }
    });
    dispatch({ type: USER_VOTE_COUNT_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: USER_VOTE_COUNT_FAIL ,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
}
