import './css/Leaderboard.css';
import VotersList from './VotersList';

const LeaderBoardListMobile = (props) => {
    const { data, color } = props.data;
    return (
        <div className="mbl_list_frame-1171277407">
            <div className="mbl_list_i-want-to-change-the-traffic-system-in-dhaka">
                {data.title && data.title.length > 40 ? `${data.title && data.title.slice(0, 40)}...` : data.title && data.title}
            </div>
            <div className="mbl_list_divider"></div>

            <div className="mbl_list_frame-1171277489">

                <div className="mbl_list_frame-1171277488">
                    <div >
                        <div className='mbl_list_frame_image_rapper_div' style={{ backgroundColor: color }}>
                            <img src={`/category_images/${data.category && data.category.toLowerCase()}.png`} width={"80%"} />
                        </div>
                    </div>
                    <div className="mbl_list_frame-120">
                        <div className="mbl_list_transportation">{data.category && data.category.toUpperCase()}</div>
                    </div>
                </div>

                <div className="mbl_list_group-812">
                    <div className="mbl_list_frame-1171277325">
                        {data.votes && data.votes.length > 0 &&
                            data.votes.slice(0, 3).map((voteData) => (
                                <VotersList
                                    profilePicture={voteData.profilePicture} />
                            ))}
                        {data.votes && data.votes.length > 0 &&
                            <>
                                <div className="VoterListellipse" >
                                    <span className='voteLengthLeaderboardList'>
                                        {data.votes && data.votes.length > 0 && data.votes.length + "+"}
                                    </span>
                                </div>
                            </>
                        }
                    </div>
                </div>

            </div>
        </div>
    )
}

export default LeaderBoardListMobile;