import React, { useState } from 'react';
import {
  TextField, Select, MenuItem, RadioGroup, FormControlLabel,
  Radio, Button, Typography, Box, FormControl, InputLabel, 
  FormLabel
} from '@mui/material';
import { useDispatch } from "react-redux";
import { JoinUsPost } from '../../actions/JoinUsActions';
import "./JoinUs.css"
import ThankYouModal from '../Modal/ThankyouModal';

const JoinUs = () => {
  const [image, setImage] = useState(null);
  const [employmentStatus, setEmploymentStatus] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [modalShow, setModalshow] = useState(false);
  const [isNotEmail, setIsNotEmail] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    category: '',
    helpDescription: '',
    employmentStatus: '',
    remoteWork: '',
    longTermPlans: '',
    companyName: '',
    designation: '',
    resume: null
  });

  const dispatch = useDispatch();


  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0]; // Get the dropped file
    setImage(file); // Set the dropped file as the selected image
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  function isValidEmail(email) {
    // Regular expression to validate an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({ ...prevData, [name]: value }));

    if(name == 'email'){
      if(isValidEmail(value)){
        setIsNotEmail(false);
      }else{
        setIsNotEmail(true);
      }
    }

  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    setImage(file); // Set the uploaded file
    const fileType = file.type;
  
    if (fileType === "application/pdf") {
      // PDF file
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl); // Set PDF preview URL
    } else if (fileType.startsWith("image/")) {
      // Image file
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl); // Set image preview URL
    } else {
      alert("Please upload a valid image or PDF file.");
    }
  
    setFormData(prevData => ({ ...prevData, resume: file })); // Set the file in form data
  };
  

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Create FormData object
    const formDataObj = new FormData();
  
    // Append all form fields
    formDataObj.append('name', formData.name);
    formDataObj.append('phone', formData.phone);
    formDataObj.append('email', formData.email);
    formDataObj.append('category', formData.category);
    formDataObj.append('helpDescription', formData.helpDescription);
    formDataObj.append('employmentStatus', formData.employmentStatus);
    formDataObj.append('remoteWork', formData.remoteWork);
    formDataObj.append('longTermPlans', formData.longTermPlans);
    formDataObj.append('companyName', formData.companyName);
    formDataObj.append('designation', formData.designation);
  
    // Append resume (file)
    if (formData.resume) {
      formDataObj.append('resume', formData.resume);  // Ensure that 'resume' matches the key used in the backend
    }
    console.log(formDataObj);
    // Dispatch the action with the FormData object
    dispatch(JoinUsPost(formDataObj));
    setModalshow(true)
  };
  
  return (
   <>
    {modalShow && 
    <ThankYouModal 
      setModalShow={setModalshow} 
      /> 
    }
    <Box className="Join-us-main-container" sx={{ maxWidth: 700, margin: 'auto', padding: 4, borderRadius: 4, mt: 3 }}>

        <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
        <Typography variant="h4" className='main-title' gutterottom>Join Our Team</Typography>
          <Box sx={{ textAlign: 'center', maxWidth: 600, margin: 'auto' }}>
          <Typography className='mainSubHead' gutterBottom>
            Become part of a movement to shape the future of Bangladesh. This is a volunteer role where your contributions will be recognized!
          </Typography>
      </Box>
      </Box>
          {/* Personal Information */}
          <Typography variant="h6" sx={{ mt: 4 }} className='titles'>Personal Information</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
            <label htmlFor="name" className='sub_title'>
                Name*
            </label>
            <TextField
              variant="outlined"
              name="name"
              fullWidth
              value={formData.name}
              onChange={handleChange}
              required
              placeholder='Your Name'
            />

            {/* Phone and Email side by side */}
            <Box sx={{ display: 'flex', gap: 2 }}>
              <div className='phone_and_email'>
                <div className='phone_and_email1'>
                <label htmlFor="name" className='sub_title'>
                    Phone*
                </label>
                <TextField
                  variant="outlined"
                  name="phone"
                  fullWidth
                  value={formData.phone}
                  onChange={handleChange}
                  required
                  placeholder='Your Phone Number'
                />
                </div>
                
                <div className='phone_and_email2'>
                <label htmlFor="name" className='sub_title'>
                    Email*
                </label>
                <TextField
                  variant="outlined"
                  name="email"
                  className={isNotEmail && "border-invalid-email" }
                  fullWidth
                  value={formData.email}
                  onChange={handleChange}
                  required
                  placeholder='Email'
                />
                {isNotEmail &&  <p className='invalid-email-address'>Invalid email address</p>}
                </div>
           
              </div>
            </Box>
          </Box>

          {/* Contribution Details */}
          <Typography variant="h6" sx={{ mt: 4 }} className='titles'>Contribution Details</Typography>
          <FormControl fullWidth variant="outlined" sx={{ mt: 2 }}>
              <label htmlFor="name" className='sub_title'>
                Category*
              </label>
            <Select
              name="category"
              value={formData.category}
              onChange={handleChange}
              required
              placeholder='Select a category'
            >
              <MenuItem value="Bug Fix">Bug Fix</MenuItem>
              <MenuItem value="Marketing Advice">Marketing Advice</MenuItem>
              <MenuItem value="Improvement">Improvement</MenuItem>
              <MenuItem value="New Feature">New Feature</MenuItem>
            </Select>
          </FormControl>
          <label htmlFor="How would you like to help us?" className='sub_title'>
              How would you like to help us?*
          </label>
          <TextField
            variant="outlined"
            name="helpDescription"
            placeholder='How would you like to help us?*'
            multiline
            rows={3}
            fullWidth
            sx={{ mt: 2 }}
            value={formData.helpDescription}
            onChange={handleChange}
            required
          />

          {/* Employment & Availability */}
          <Typography variant="h6" sx={{ mt: 4 }} className='titles'>Employment & Availability</Typography>
          <Box sx={{ mt: 2 }}>
            <label htmlFor="Are you currently full-time employed?" className='sub_title'>
              Are you currently full-time employed?*
            </label>
              <RadioGroup
                placeholder='Are you currently full-time employed?*'
                name="employmentStatus"
                value={formData.employmentStatus}
                onChange={handleChange}
                row
              >
              <FormControlLabel className='radioClass' value="Yes" control={<Radio />} label="Yes" onClick={()=>setEmploymentStatus(true)}/>
              <FormControlLabel className='radioClass' value="No" control={<Radio />} label="No"  onClick={()=>setEmploymentStatus(false)}/>
            </RadioGroup>
          </Box>





        {employmentStatus && 
            <Box sx={{ display: 'flex', gap: 2 }}>
            <div className='phone_and_email'>
              <div className='phone_and_email1'>
              <label htmlFor="comapnyName" className='sub_title'>
                  Comapny Name*
              </label>
              <TextField
                variant="outlined"
                name="companyName"
                fullWidth
                value={formData.companyName}
                onChange={handleChange}
                required
                placeholder='Your Company Name'
              />
              </div>
              
              <div className='phone_and_email2'>
              <label htmlFor="designation" className='sub_title'>
                  Designation*
              </label>
              <TextField
                variant="outlined"
                name="designation"
                value={formData.designation}
                onChange={handleChange}
                required
                placeholder='Designation'
              />
              </div>
            </div>
          </Box>
        }

          <Box sx={{ mt: 2 }}>
            <label htmlFor="Are you available to work remotely?" className='sub_title'>
              Are you available to work remotely?*
            </label>
            <RadioGroup
              name="remoteWork"
              placeholder="Are you available to work remotely?*"
              value={formData.remoteWork}
              onChange={handleChange}
              row
            >
              <FormControlLabel className='radioClass' value="Yes" control={<Radio />} label="Yes" />
              <FormControlLabel className='radioClass' value="No" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>

          <label htmlFor="What are your long-term plans with us?" className='sub_title'>
              What are your long-term plans with us?*
          </label>
          <TextField
            variant="outlined"
            name="longTermPlans"
            placeholder='What are your long-term plans with us?*'
            multiline
            rows={3}
            fullWidth
            sx={{ mt: 2 }}
            value={formData.longTermPlans}
            onChange={handleChange}
            required
          />

          {/* Additional Document */}
          <Typography variant="h6" sx={{ mt: 4, mb:2 }} className='titles'>Additional Document</Typography>


          {!image ?
        <>
          {/* Image Upload */}
          <div className="form-group" >
          <div className="frame-1171276623" 
                onDrop={handleDrop}
                onDragOver={handleDragOver}>
            <div className="frame-1171276668">
              <div className="cloud-computing-1">
              <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_591_942)">
                <path d="M29.9945 13.2332C28.3307 6.60498 21.6088 2.58059 14.9806 4.24442C9.80078 5.54471 6.03968 10.0215 5.65216 15.3479C1.97431 15.9544 -0.515536 19.4275 0.0909836 23.1054C0.630151 26.375 3.46329 28.7696 6.77704 28.7564H12.4014V26.5067H6.77704C4.29205 26.5067 2.27753 24.4921 2.27753 22.0071C2.27753 19.5222 4.29205 17.5076 6.77704 17.5076C7.39832 17.5076 7.90192 17.004 7.90192 16.3828C7.89629 10.7915 12.4244 6.2543 18.0157 6.24874C22.8557 6.24389 27.0222 9.66563 27.9585 14.4142C28.0509 14.8884 28.4357 15.2506 28.9146 15.3141C31.9899 15.7521 34.1278 18.6 33.6899 21.6752C33.2967 24.4367 30.939 26.4927 28.1497 26.5067H23.6502V28.7564H28.1497C32.4985 28.7433 36.0132 25.2072 35.9999 20.8584C35.989 17.2384 33.5111 14.0922 29.9945 13.2332Z" fill="#2E3A59"/>
                <path d="M17.228 17.834L12.7285 22.3335L14.3146 23.9195L16.9018 21.3436V32.1311H19.1516V21.3436L21.7275 23.9195L23.3136 22.3335L18.8141 17.834C18.3753 17.3978 17.6668 17.3978 17.228 17.834Z" fill="#2E3A59"/>
                </g>
                <defs>
                <clipPath id="clip0_591_942">
                <rect width="36" height="36" fill="white"/>
                </clipPath>
                </defs>
              </svg>
              </div>
              <div className="frame-1171276669">
                <div className="frame-1171276667">
                  <div className="drag-drop-or-browser-file">
                      <span className="drag-drop-or-browser-file-span2">
                          <input
                            id="image"
                            type="file"
                            accept="*"
                            onChange={handleFileUpload}
                            style={{ display: 'none' }} // Hide the actual input
                          />
                          <label htmlFor="image" className="drag-drop-or-browser-file-span2">
                            <span className="drag-drop-or-browser-file-span">
                                Drag &amp; Drop or
                              </span> <span style={{textDecoration: "underline"}}> Browse File </span>
                              {/* image && <p>Selected file: {image.name}</p>*/}


                          </label>
                   
                        </span>
                  </div>
                  <div className="max-file-size-limit-2-mb">(Max. file size limit: 1.5mb)</div>
                </div>
              </div> 
            </div>
          </div>
        </div>
        </>: 
        <>
        <div className="upload_have_img-frame-1171276623" style={{marginTop: "-10px"}}>
          <div className="upload_have_img-frame-1171277540">
             {/* Conditionally render PDF or image preview */}
        {image.type === "application/pdf" ? (
          // PDF Viewer
          <embed src={imagePreview} type="application/pdf" width="100%" height="100px" />
        ) : (
          // Image Viewer
          <img className="upload_have_img-rectangle-318" src={imagePreview} alt={image.name} />
        )}
            <div className="upload_have_img-frame-1171276667">
              <div className="upload_have_img-frame-1171277541">
                <div className="image-1-jpg">{image.name}</div>
                <div className="upload_have_img-_1-96-mb"></div>
              </div>
            </div>
          </div>
          <span className="drag-drop-or-browser-file-span3">
            <input
              id="image"
              type="file"
              accept="*"
              onChange={handleFileUpload}
              style={{ display: 'none' }} // Hide the actual input
            />
            <label htmlFor="image" className="drag-drop-or-browser-file-span3">
              <span className="drag-drop-or-browser-file-span">
                </span> <span style={{textDecoration: "underline"}}> Upload Again </span>
                {/* image && <p>Selected file: {image.name}</p>*/}
            </label>
          </span>
        </div>

        </>
      }


          {/* Submit */}
          <button className='submit_btn_joinus'>
            Submit
          </button>
        </form>
      </Box>
   </>
  );
};

export default JoinUs;
