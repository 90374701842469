import './giveAFeedback.css'
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import { Select, MenuItem, Box, Typography, FormControl, InputLabel } from '@mui/material';
import { useDispatch } from "react-redux";
import { FeedBackPost } from '../../actions/FeedBackActions';
import ThankYouModal from '../Modal/ThankyouModal';

const GiveAFeedBack = () =>{
    const feedbackCategories = [
        { id: 1, name: "Technical Bug" },
        { id: 2, name: "Improvement on The User Experience" },
        { id: 3, name: "New Function/Feature" },
        { id: 4, name: "Marketing Feedback" },
        { id: 5, name: "Partnership Offer" }
    ];
    
    const [selectedCategory, setSelectedCategory] = useState('');
    const [description, setDescription] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const userInfo = JSON.parse(localStorage.getItem('PoribortonchaiUserData'));
    const [descriptionLength, setDescriptionLength] = useState(0);
    const [modalShow, setModalshow] = useState(false);

    const navigate = useNavigate();  // Initialize useNavigate for redirection

    const dispatch = useDispatch();
  
    // Fetch categories from the backend when the component mounts
    useEffect(() => {
      if(!userInfo){
        navigate('/login');
      }  
    }, []);

    const handleSubmit = (e) => {
      e.preventDefault();
      setIsSubmitting(true)
      dispatch(FeedBackPost(selectedCategory, description));
      setModalshow(true)
    };
  

    const setDiscriptionFunc = (e) => {
      const inputValueOfDesc = e.target.value;
    
      // Set the title and the length of the input directly based on the current value
      setDescription(inputValueOfDesc);
      setDescriptionLength(inputValueOfDesc.length); // Update the titleLength to match the input length
    };
    
    
    return (
      <>
    {modalShow && 
    <ThankYouModal 
      setModalShow={setModalshow} 
      /> 
    }
      <div className="pitch-idea-container">
        
  
        <form className="pitch-idea-form" onSubmit={handleSubmit}>
        <div class="pitch-my-idea">Give A Feedback</div>
          <div className="form-group">
            <div className="category-label-pitch-ideas-insert">Category*</div>
            <FormControl fullWidth variant="outlined" sx={{ minWidth: 240, maxHeight: 40 }}>
              <Select
               className='txtClassPitchIdeaInsert'
                labelId="category-select-label"
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
                required
                displayEmpty
                sx={{
                  height: '45px',
                  padding: '0px',
                }}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 300, // Control max height of the dropdown list
                    },
                  },
                }}
              >
                <MenuItem value="" disabled>
                  <p>Select Category</p>
                </MenuItem>
                {feedbackCategories.map((category) => (
                  <MenuItem key={category.id} value={category.name}>
                    <Box display="flex" alignItems="center">
                      <Typography>{category.name}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
  
          {/* Description Input */}
          <div className="form-group" style={{marginTop: "10px"}}>
            <label htmlFor="description" className="category-label-pitch-ideas-insert">How Should we fix this issue?*</label>
            <textarea
              id="description"
              className='Description_Insertion_Pitch'
              value={description}
              onChange={(e) => setDiscriptionFunc(e)}
              required
              maxLength={10000}
            />
              <p className='length_of_pitch_txt'>{descriptionLength}/10000</p>
          </div>

          <div class="frame-1171275240">
            <div class="pitch">
              <button type="submit" disabled={isSubmitting} style={{display: "hidden"}}>
              {isSubmitting ? 'Submit' : 'Submit'}
            </button>
            </div>
          </div>
        </form>
        
      </div>
      </>
    );
}

export default GiveAFeedBack;