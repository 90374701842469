import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/MobileHeader.css';

const MobileHeader = () => {
  const navigate = useNavigate();

  return (
    <header className="mobile-header">
      <button className="back-button" onClick={() => navigate(-1)}>
        &#8592; {/* This is the left arrow symbol */}
      </button>
      <h1 className="header-title">
        <Link to="/" style={{textDecoration: "none"}}>
          <img src="/logo.png" alt="Logo" className="logo" style={{width: "42.5%"}}/>
        </Link>
      </h1>
    </header>
  );
};

export default MobileHeader;
