import React from 'react';
import PitchIdea from '../components/PitchIdea';

const PitchPage = () => {
  return (
    <div className="pitch-page">
      <PitchIdea />
    </div>
  );
}

export default PitchPage;
